<template>
  <div class="how-to-deliver-page">
    <page-header
      :cover="require('../assets/images/objects.png')"
      :title="$t('howToDeliver')"
      :desc="$t('howToDeliverDesc')"
      background-position="back-right"
    />
    <div class="container">
      <div
        :class="['row', 'pip-line', 'align-items-start', $activeLang]"
        :style="`background-image: url('${require('../assets/images/group-1.png')}')`"
      >
        <div
          class="col-md-6 col-lg-3 col-12 mx-auto step"
          v-for="(step, stepInd) in pipLineSteps"
          :key="stepInd"
        >
          <img
            src="../assets/images/line-indicator.png"
            alt="line"
            class="fly-img"
          />
          <strong>{{ step.title }}</strong>
          <p class="sec-desc">
            {{ step.description }}
          </p>
        </div>
      </div>
      <div class="ideate row">
        <div class="col-md-7 col-lg-6 col-12">
          <h2 class="sec-title mb-4">{{ $t("ideate") }}</h2>
          <p class="sec-desc mb-4">
            {{ $t("ideateDescLong") }}
          </p>
          <swiper-actions @prev="prev" @next="next" :small="false" />
        </div>
        <div class="col-md-5 col-lg-6 col-12">
          <div class="d-flex swiper">
            <swiper ref="ideateSwiper" :options="swiperOptions">
              <swiper-slide :key="serI" v-for="(serivce, serI) in services">
                <service-card :service="serivce" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="design row">
        <div class="col-lg-6 col-12">
          <h2 class="sec-title mb-3">{{ $t("design") }}</h2>
          <p class="sec-desc mt-5 ms-0">
            {{ $t("designDescLong") }}
          </p>
        </div>
        <div class="col-lg-6 col-12">
          <img
            src="../assets/images/iterative-process-diagram.png"
            alt="Process Digram"
            class="img-fit"
          />
        </div>
      </div>
      <div class="features row">
        <div
          class="col-md-4 col-lg-3 col-6"
          v-for="(feat, feati) in features"
          :key="feati"
        >
          <div class="feat-card my-card">
            <h3>{{ feat.title }}</h3>
            <img :src="feat.img" :alt="feat.title + ' image'" />
          </div>
        </div>
      </div>
      <div class="develope row">
        <div class="col-lg-8 col-12">
          <h2 class="sec-title mb-5">{{ $t("develop") }}</h2>
          <p class="sec-desc">
            {{ $t("developDescLong") }}
          </p>
        </div>
        <div class="col-lg-4 col-12 mt-5 mt-lg-0 text-center">
          <img
            src="../assets/images/develope-digram.png"
            alt="Develope Process Digram"
            class="img-fit"
          />
        </div>
      </div>
      <div class="design row my-5">
        <div class="col-lg-6 col-12">
          <h2 class="sec-title mb-3">{{ $t("improve") }}</h2>
          <p class="sec-desc mt-5 ms-0">
            {{ $t("improveDescLong") }}
          </p>
          <div class="row mt-5">
            <div class="col-5 d-flex info mb-3">
              <img
                src="../assets/images/check.png"
                class="mx-2"
                alt="Check Marck"
              />
              {{ $t("contentManagement") }}
            </div>
            <div class="col-5 d-flex info mb-3">
              <img
                src="../assets/images/check.png"
                class="mx-2"
                alt="Check Marck"
              />
              {{ $t("securityMonitoring") }}
            </div>
          </div>
          <div class="row mb-5 mb-lg-0 align-items-center">
            <div class="col-5 d-flex info mb-3">
              <img
                src="../assets/images/check.png"
                class="mx-2"
                alt="Check Marck"
              />
              {{ $t("uIUXenhancement") }}
            </div>
            <div class="col-5 d-flex info mb-3">
              <img
                src="../assets/images/check.png"
                class="mx-2"
                alt="Check Marck"
              />
              {{ $t("periodicUpdates") }}
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <img
            src="../assets/images/iterative-process-diagram.png"
            alt="Process Digram"
            class="img-fit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from "../components/Layout/ServiceCard.vue";
import PageHeader from "../components/misc/PageHeader.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import SwiperActions from "../components/Layout/SwiperActions.vue";
export default {
  components: {
    PageHeader,
    ServiceCard,
    SwiperSlide,
    Swiper,
    SwiperActions,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        loop: true,
      },
      pipLineSteps: [
        {
          title: this.$t("ideate"),
          description: this.$t("ideateDesc"),
        },
        {
          title: this.$t("design"),
          description: this.$t("designDesc"),
        },
        {
          title: this.$t("develop"),
          description: this.$t("developDesc"),
        },
        {
          title: this.$t("improve"),
          description: this.$t("improveDesc"),
        },
      ],
      features: [
        {
          title: this.$t("Mobile Responsive"),
          img: require("../assets/images/01.png"),
        },
        {
          title: this.$t("User Friendly"),
          img: require("../assets/images/02.png"),
        },
        {
          title: this.$t("Well Organised Layers"),
          img: require("../assets/images/03.png"),
        },
        {
          title: this.$t("Easily Customisable"),
          img: require("../assets/images/easily-cu.png"),
        },
        {
          title: this.$t("Better Components"),
          img: require("../assets/images/better-com.png"),
        },
        {
          title: this.$t("Multiple Blocks"),
          img: require("../assets/images/multi-block.png"),
        },
        {
          title: this.$t("Robust Workflow"),
          img: require("../assets/images/workflow.png"),
        },
        {
          title: this.$t("Mobile Responsive"),
          img: require("../assets/images/responsive.png"),
        },
      ],
      services: [
        {
          logo: "laptop",
          title: this.$t("freeConsultationSession"),
          desc: this.$t("freeConsultationSessionDesc"),
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
        },
        {
          logo: "data",
          title: this.$t("requirementsAnalysis"),
          desc: this.$t("requirementsAnalysisDesc"),
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 2,
        },
        {
          logo: "edit",
          title: this.$t("budgetTimeEstimation"),
          desc: this.$t("budgetTimeEstimationDesc"),
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 2,
        },
      ],
    };
  },
  methods: {
    next() {
      this.swiper.slideNext(400);
    },
    prev() {
      this.swiper.slidePrev(400);
    },
  },
  computed: {
    swiper() {
      return this.$refs.ideateSwiper.$swiper;
    },
  },
};
</script>

<style>
</style>
